<template>
  <div class="container">
    <div class="row body">
      <div class="col-12 create-post">
        <div class="post-form">
          <b-form @submit.prevent="EditPost">
            <b-form-group
              id="title"
              :label="$t('create_post.title')"
              label-for="title"
            >
              <b-form-input
                id="input-1"
                type="text"
                :placeholder="$t('create_post.enter title')"
                required
                v-model="update_title"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="content"
              :label="$t('create_post.content')"
              label-for="content"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="update_content"
                :options="editorOption"
                @change="handleTextChange($event)"
              />
            </b-form-group>
            <input type="file" @change="handleFileUpload($event)" />
            <div class="text-center">
              <b-button
                type="submit"
                variant="success"
                class="vh text-center"
                align-v="center"
                :disabled="isDisabled"
                >{{ $t("create_post.submit") }}</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "../../../libs/axiosConfig";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import Quill from "quill";
import ImageResize from "quill-image-resize";
Quill.register("modules/imageResize", ImageResize);
export default {
  name: "edit-post",
  components: { quillEditor },
  props: ["title", "content", "thumbnail", "id", "images"],
  data() {
    return {
      isDisabled: false,
      url: process.env.VUE_APP_API_URL,
      update_title: this.title,
      update_content: this.content,
      update_thumbnail: this.thumbnail,
      update_images: this.images,
      update_file: "",
      editorOption: {
        modules: {
          imageResize: {
            displaySize: true,
          },
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block"],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"],
              ["image"],
              ['link', 'video']
            ],
            handlers: {
              image: this.imageHandler,
            },
          },
        },
        theme: "snow",
      },
    };
  },
  methods: {
    imageHandler() {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      input.onchange = async function () {
        const file = input.files[0];
        let quill = this.$refs.myQuillEditor.quill;
        const range = quill.getSelection();
        let formData = new FormData();
        formData.append("file", file);
        axiosIns
          .post("/img", formData)
          .then((res) => {
            quill.insertEmbed(range.index, "image", this.url +res.data.file_url);
            this.update_images.push(res.data.file_url)
          })
          .catch((err) => {
            console.log(err.response);
          });
      }.bind(this);
    },
    handleFileUpload(event) {
      this.update_file = event.target.files[0];
    },
    handleTextChange(event){
      let undo = event.quill.history.stack.undo
      let ops = undo[undo.length-1].undo.ops
      let ops_index = ops[1] ? ops[1] : ops[0]
      let insert = null
      try{
        insert = ops_index.insert
      }catch(err){
        insert = null
      }
      if(insert && insert.image){
        let image = insert.image.toString().substr(35)
        axiosIns.post("/deleteFile",{
          images: [image],
          type: "post"
        }).then((res)=>{
          this.update_images = this.update_images.filter(item=> item !== image)
        })
        .catch((err)=>{
          console.log(err.response)
        })
      }
    },
    async EditPost() {
      this.isDisabled = true;
      let post = {
        title: this.update_title,
        content: this.update_content,
        thumbnail: this.update_thumbnail,
        images: this.update_images
      };
      if(this.update_file != ""){
        let formData = new FormData();
        formData.append("file", this.update_file);
        await axiosIns
        .post("/img", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          axiosIns.post("/deleteFile",{
            images: [post.thumbnail],
            type: "post"
          }).catch((err)=>{
            console.log(err.response)
          })
          post.thumbnail = response.data.file_url;
        })
        .catch(function (error) {
          console.log("FAILURE111!!", error.response);
        });
      }
      await axiosIns
        .patch("/post/" + this.id, post)
        .then((response) => {
          this.$emit("edit-success", response.data);
          this.$root.$emit("bv::hide::modal", "" + this.id);
        })
        .catch((error) => {
          console.log("ERRRR:: ", error.response);
          this.isDisabled = false;
        });
    },
  },
};
</script>

<style lang="scss">
.quill-editor {
  .ql-container {
    .ql-editor {
      overflow: auto;
      height: 400px;
    }
  }
}
</style>
