<template>
  <LargeLayout>
    <div class="col-12 create-post">
      <h1 class="text-center text-success">
        {{ $t("create_post.Create Post") }}
      </h1>
      <div class="d-flex justify-content-between my-3">
        <button
          v-b-modal.modal-1
          class="text-white bg-success border-0 px-3 py-2 border-radius-sm"
        >
          {{ $t("create_post.New Post") }}
        </button>
      </div>

      <b-modal
        id="modal-1"
        size="lg"
        :title="$t('create_post.Create New Post')"
        :hide-footer="true"
      >
        <CreatePost @create-success="createSuccess" />
      </b-modal>
      <div v-for="post in posts" :key="post._id" class="single-post">
        <div class="row">
          <div class="col-7 col-lg-4">
            <router-link :to="'/post/' + post._id">
              <img
                alt="thumbnail"
                :src="url + post.thumbnail"
            /></router-link>
          </div>
          <div class="col-5 col-lg-8">
            <h6 class="post-createdAt">{{ post.createdAt | format1 }}</h6>

            <router-link :to="'/post/' + post._id">
              <h6 class="post-title">{{ post.title }}</h6></router-link
            >
            <hr class="hr1" />
            <p class="content d-none d-md-block" v-html="post.content"></p>
          </div>
        </div>
        <br />
        <div class="admin-buttons">
          <button
            @click="$bvModal.show(`${post._id}`)"
            class="
              text-white
              bg-success
              border-0
              px-3
              py-2
              border-radius-sm
              admin-button
            "
          >
            {{ $t("create_post.Edit") }}
          </button>
          <button
            @click="$bvModal.show(`delete-post-${post._id}`)"
            class="
              text-white
              bg-success
              border-0
              px-3
              py-2
              border-radius-sm
              admin-button
            "
          >
            {{ $t("create_post.Delete") }}
          </button>
        </div>
        <hr class="border-3 border-success hr2" />
        <b-modal
          :id="`delete-post-${post._id}`"
          :ok-title="$t('create_post.Yes')"
          :cancel-title="$t('create_post.No')"
          :title="$t('create_post.Delete Post')"
          @ok="deletePost(post._id)"
        >
          {{ $t("create_post.Are you sure to delete this post") }}</b-modal
        >
        <b-modal
          :id="post._id"
          size="lg"
          :hide-footer="true"
          :title="$t('create_post.Edit Post')"
        >
          <EditPost
            @edit-success="editSuccess"
            :id="post._id"
            :title="post.title"
            :content="post.content"
            :thumbnail="post.thumbnail"
            :images="post.images"
          />
        </b-modal>
      </div>
      <b-pagination
        align="center"
        v-if="posts.length > 0"
        v-model="page"
        :total-rows="count"
        :per-page="size"
        @change="handlePageChange"
        aria-controls="history-list"
      ></b-pagination>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "@/layout/main/LargeLayout.vue";
import CreatePost from "@/views/admin/components/Create-Post.vue";
import EditPost from "@/views/admin/components/EditPost.vue";
import axiosIns from "../../libs/axiosConfig";

export default {
  components: { LargeLayout, CreatePost, EditPost },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      posts: [],
      size: 6,
      page: 1,
      count: 0,
      message: {
        created: {
          type: "success",
          title: this.$t("toast.Success"),
          text: this.$t("toast.Created_post"),
        },
        deleted: {
          type: "success",
          title: this.$t("toast.Success"),
          text: this.$t("toast.Deleted"),
        },
        edited: {
          type: "success",
          title: this.$t("toast.Success"),
          text: this.$t("toast.Edited"),
        },
        error: {
          type: "danger",
          title: this.$t("toast.Error"),
          text: this.$t("toast.Something went wrong"),
        },
      },
    };
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    handlePageChange(value) {
      this.page = value;
      axiosIns
        .get("/post?page=" + this.page + "&size=" + this.size)
        .then((response) => {
          this.posts = response.data.posts;
          this.count = response.data.totalItems;
        });
    },
    deletePost(id) {
      axiosIns
        .delete("/post/" + id)
        .then((response) => {
          const index = this.posts.findIndex((item) => item._id === id);
          this.posts.splice(index, 1);
          this.makeToast("deleted");
        })
        .catch((err) => {
          console.log(err.response);
          this.makeToast("error");
        });
    },
    editSuccess(data) {
      const index = this.posts.findIndex((item) => item._id === data._id);
      this.posts.splice(index, 1, data);
      this.makeToast("edited");
    },
    createSuccess(data) {
      this.posts.unshift(data);
      this.makeToast("created");
    },
  },
  created() {
    // console.log(this.message);
    axiosIns
      .get("/post?page=" + this.page + "&size=" + this.size)
      .then((response) => {
        this.posts = response.data.posts;
        this.count = response.data.totalItems;
      });
  },
};
</script>

<style lang="scss">
.create-post {
  .admin-buttons {
    display: flex;
    gap: 10px;
    .admin-button {
      min-width: 70px !important;
    }
  }
}
.single-post {
  .row {
    display: flex;
    justify-content: space-between;
    .col-5 {
      .content {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 25px;
        -webkit-line-clamp: 2;
        height: 50px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        font-weight: bold;
      }
      .hr1 {
        border-top: 0.07cm solid var(--success);
        opacity: 1;
        color: var(--success);
        margin-top: 0px;
      }
      .post-title {
        font-weight: bold;
        text-transform: uppercase;
        color: var(--post-title-blue);
        margin-bottom: 3px !important;
      }
      a:hover {
        text-decoration: none;
      }
      .post-createdAt {
        font-weight: bold;
      }
    }
    .col-7 {
      padding-right: 0px;
      img {
        object-fit: cover;
        width: 100%;
        height: auto;
        aspect-ratio: 16/9 ;
        // max-height: 150px;
      }
    }
  }
  .hr2 {
    border-top: 0.07cm solid var(--success);
    opacity: 1;
    color: var(--success);
  }
}
</style>
